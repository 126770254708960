<template>
  <div class="app-login">
    <div id="banner">
      <div>
        <img width="250px" src="@/assets/img/insideBrasil.png" alt="" />
      </div>
    </div>
    <div id="login">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.app-login {
  display: grid;
  grid: "banner form" 100vh / 2fr 1fr;
  height: 100vh;
  background: #e7e7e7;
}
#login {
  width: 100%;
  grid-area: form;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
}
#banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #221e1e;
  width: 100%;
  grid-area: banner;
}

@media only screen and (max-width: 768px) {
  .app-login {
    grid: "banner" "form" / 1fr;
    height: fit-content;
  }
  #banner {
    height: 30vh;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #login {
    overflow: initial;
  }
}
</style>
